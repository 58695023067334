import { ChatMessage, ProChat, ProChatInstance } from "@ant-design/pro-chat";
import {
    useApiUrl,
    useCreate,
    useDelete,
    useInvalidate,
    useList,
    useOne,
} from "@refinedev/core";
import "./style.css";
import { createPortal } from "react-dom";
import { useEffect, useMemo, useRef, useState } from "react";
import Color from "color";
import ColorButton from "components/ColorButton";
import { ISuggestion } from "types";
import { Locale } from "@ant-design/pro-chat/es/locale";
import { AssistantType } from "pages/assistants/types";
import { TOKEN_KEY } from "../../authProvider";
import { Select } from "antd/lib";
import { useSelect } from "@refinedev/antd";
import { Button, Col, Row } from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";

const actionsRender = () => {
    return <div></div>;
};

interface SuggestionProps {
    suggestions: ISuggestion[];
    sendMessage: (content: string) => void;
}

const SuggestionComponent: React.FC<SuggestionProps> = ({
    suggestions,
    sendMessage,
}) => {
    const [actionBar, setActionBar] = useState<Element>(document.body);

    useEffect(() => {
        const intervalId = setInterval(() => {
            const actionBarElement = document.querySelector(
                ".ant-pro-chat-input-area-action-bar"
            );
            // take the child ant-btn-icon-only from the action bar and remove it
            const child = actionBarElement?.querySelector(
                ".ant-btn-icon-only"
            );
            if (child) {
                child.remove();
            }
            if (actionBarElement) {
                setActionBar(actionBarElement);
                clearInterval(intervalId);
            }
        }, 100);

        return () => clearInterval(intervalId);
    }, []);

    if (!actionBar) {
        return null;
    }

    const backgroundColor = "#f0f2f5";

    const suggestionColor = Color(backgroundColor).isDark()
        ? Color(backgroundColor).lighten(1.6).hex()
        : Color(backgroundColor).darken(1.6).hex();

    const textColor = Color(suggestionColor).isDark() ? "white" : "black";

    // turn suggestions into buttons
    const suggestionButtons = suggestions.map((suggestion) => (
        <ColorButton
            hoverTransparency={true}
            key={suggestion.id}
            override={`${suggestionColor}55`}
            shape="round"
            onClick={(e) => {
                e.preventDefault();
                sendMessage(suggestion.content);
            }}
            size="middle"
            style={{
                fontSize: "15px",
                pointerEvents: "auto",
                color: textColor,
                whiteSpace: "normal",
                overflow: "hidden",
                display: "inline-block",
                width: "auto",
                height: "auto",
            }}
            type="primary"
            className="mr-1.5 mb-1.5 pb-0 pt-0"
            children={<p className="mb-0 no-underline">{suggestion.content}</p>}
        />
    ));

    return createPortal(
        <>
            <div style={{ flex: 1 }} />
            <div>{suggestionButtons}</div>
        </>,
        actionBar
    );
};

const sendMessageToServer = async (
    apiUrl: string,
    assistantId: number = 0,
    assistantUuid: string | null = null,
    message: ChatMessage,
    isPublic = false
) => {
    const chatIdKey = isPublic ? "publicChatId" : "chatId";
    const meta =
        typeof message.content === "string"
            ? message.content.match(/<META>(.*)<\/META>/)
            : null;
    let action_type = null;
    let questionId = null;
    let answerId = null;
    if (meta) {
        const data = JSON.parse(meta[1]);
        action_type = data.actionType;
        questionId = data.questionId;
        answerId = data.answerId;
    }
    const data: {
        [key: string]: string | number | React.ReactNode | null;
        assistant_id: number;
        assistant_uuid: string | null;
        message: React.ReactNode;
        chat_message_history_id: number;
        action_type: "explain" | "hint" | null;
        question_id: string | null;
        answer_id: string | null;
    } = {
        assistant_id: assistantId,
        assistant_uuid: assistantUuid,
        message: message.content,
        chat_message_history_id: Number(sessionStorage.getItem(chatIdKey)),
        action_type: action_type,
        question_id: questionId,
        answer_id: answerId,
    };
    // throw away the null ones
    Object.keys(data).forEach((key) => data[key] === null && delete data[key]);
    const url = isPublic
        ? `${apiUrl}/public/assistant-stream/`
        : `${apiUrl}/assistant-stream/`;
    const response = await fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
        },
        body: JSON.stringify(data),
    });
    const reader = response?.body?.getReader();
    const stream = new ReadableStream({
        start(controller) {
            function pump(): Promise<void | undefined> | undefined {
                return reader?.read().then(({ done, value }) => {
                    if (done) {
                        controller.close();
                        return;
                    }
                    controller.enqueue(value);
                    return pump();
                });
            }
            return pump();
        },
    });

    return new Response(stream);
};

interface IChatMessageHistory {
    id: number;
    name: string;
}

export const Assistant = ({
    assistantId,
    assistantUuid,
    height = "100vh",
    onEvent,
    isPublic = false,
}: {
    assistantId: number;
    assistantUuid?: string;
    height?: string;
    onEvent?: {
        type: "explain" | "hint";
        payload: {
            is_correct?: boolean;
            question?: string;
            questionId?: string;
            questionTitle?: string;
            answer?: string;
            answerId?: string;
        };
    } | null;
    isPublic?: boolean;
}) => {
    const apiUrl = useApiUrl();

    const chatIdKey = isPublic ? "publicChatId" : "chatId";

    const [selectedValue, setSelectedValue] = useState({
        name: "New Chat",
        value: -1,
    });

    const { data: assistantData, isLoading: isAssistantLoading } =
        useOne<AssistantType>({
            resource: isPublic ? "public/assistants" : "assistants",
            id: isPublic ? assistantUuid : assistantId,
        });

    const chatRef = useRef<ProChatInstance>();
    const [suggestions, setSuggestions] = useState<ISuggestion[]>([]);

    useEffect(() => {
        if (onEvent) {
            setSuggestions([]);
            if (onEvent.type === "explain" && onEvent.payload.question) {
                chatRef.current?.sendMessage(
                    `<META>{"actionType": "explain", "questionId": "${onEvent.payload?.questionId}", "answerId": "${onEvent.payload?.answerId}", "questionTitle": "${onEvent.payload?.questionTitle}"}</META>` // `Fully explain the following problem: ${onEvent.payload.question}. My answer was ${onEvent.payload.answer}`
                );
                chatRef.current?.pushChat({
                    content: "Explain " + onEvent.payload?.questionTitle,
                    role: "user",
                });
            } else if (onEvent.type === "hint" && onEvent.payload.question) {
                chatRef.current?.sendMessage(
                    `<META>{"actionType": "hint", "questionId": "${onEvent.payload?.questionId}", "questionTitle": "${onEvent.payload?.questionTitle}"}</META>` // `Fully explain the following problem: ${onEvent.payload.question}. My answer was ${onEvent.payload.answer}`
                );
                chatRef.current?.pushChat({
                    content:
                        "Give me a hint for " + onEvent.payload?.questionTitle,
                    role: "user",
                });
            }
        }
    }, [onEvent]);

    useEffect(() => {
        if (assistantData?.data?.initial_suggestions) {
            setSuggestions(assistantData.data.initial_suggestions);
        }
    }, [assistantData]);

    const localeMapper: { [key: string]: Locale } = {
        sl: "sl-SI",
        en: "en-US",
    };
    const locale = localeMapper[assistantData?.data?.language] || "en-US";
    const initialMessage = assistantData?.data?.initial_message || "";

    const currentChatId = sessionStorage.getItem(chatIdKey) || "";

    const { selectProps: chatMessageHistorySelectProps, queryResult } =
        useSelect<IChatMessageHistory>({
            resource: "chat-message-histories",
            optionLabel: "name",
            optionValue: "id",
            queryOptions: {
                enabled: !isPublic,
            },
        });
    const currentChat = queryResult.data?.data?.find(
        (chat: IChatMessageHistory) => chat.id === Number(currentChatId)
    ) || { name: "New Chat", value: -1 };

    const { data: messagesData } = useList({
        resource: "chat-messages",
        queryOptions: {
            enabled: !!currentChatId,
        },
        filters: [
            {
                field: "chat_message_history_id",
                operator: "eq",
                value: currentChatId,
            },
        ],
    });

    // sort and memoize messages
    const sortedMessages = useMemo(() => {
        if (messagesData?.data) {
            return messagesData.data.sort(
                (a: { id: number }, b: { id: number }) => a.id - b.id
            );
        }
        return [];
    }, [messagesData]);

    useEffect(() => {
        setSelectedValue({
            name: currentChat?.name,
            value: Number(currentChatId),
        });
    }, [queryResult.data?.data]);

    const { mutate: mutateCreate } = useCreate<IChatMessageHistory>();
    const { mutate: mutateDelete } = useDelete<IChatMessageHistory>();
    const invalidate = useInvalidate();

    const newChat = async () => {
        mutateCreate(
            {
                resource: "chat-message-histories",
                values: {
                    assistant: assistantId,
                },
            },
            {
                onSuccess: (data: { data: { id: string; name: string } }) => {
                    invalidate({
                        resource: "chat-messages",
                        invalidates: ["list"],
                    });
                    invalidate({
                        resource: "chat-message-histories",
                        invalidates: ["list"],
                    });
                    // set current chat
                    sessionStorage.setItem(chatIdKey, data?.data?.id);
                    // also set the chat id in the chat select
                    const sel = {
                        value: Number(data?.data?.id),
                        name: data?.data?.name,
                    };
                    setSelectedValue(sel);
                },
            }
        );
    };

    const deleteChat = async () => {
        mutateDelete(
            {
                resource: "chat-message-histories",
                id: currentChatId,
            },
            {
                onSuccess: () => {
                    invalidate({
                        resource: "chat-messages",
                        invalidates: ["list"],
                    });
                    invalidate({
                        resource: "chat-message-histories",
                        invalidates: ["list"],
                    });
                    // set current chat
                    sessionStorage.setItem(chatIdKey, "-1");
                    // also set the chat id in the chat select
                    const sel = {
                        value: -1,
                        name: "New Chat",
                    };
                    setSelectedValue(sel);
                },
            }
        );
    };

    if (isAssistantLoading || !isPublic && queryResult.isLoading) {
        return null;
    }

    return (
        <div>
            {!isPublic && (
                <Row
                    gutter={[16, 16]}
                    justify="space-between"
                    className="px-4 flex pb-4"
                >
                    <Col className="flex grow">
                        <Select
                            {...chatMessageHistorySelectProps}
                            size="middle"
                            rootClassName="grow"
                            defaultValue={{
                                value: currentChat?.id,
                                name: currentChat?.name,
                                // @ts-expect-error Ignore this error
                                label: currentChat?.name,
                            }}
                            value={selectedValue}
                            onChange={(value) => setSelectedValue(value)}
                            onSelect={(value) => {
                                sessionStorage.setItem(
                                    chatIdKey,
                                    value.toString()
                                );
                                invalidate({
                                    resource: "chat-messages",
                                    invalidates: ["list"],
                                });
                            }}
                        />
                    </Col>
                    {currentChat?.value !== -1 && (
                        <>
                            <Col className="flex">
                                <Button
                                    onClick={newChat}
                                    icon={<PlusOutlined />}
                                >
                                    New Chat
                                </Button>
                            </Col>
                            {/* <Col className="flex">
                                <Button
                                    onClick={deleteChat}
                                    icon={<DeleteOutlined />}
                                >
                                    Delete
                                </Button>
                            </Col> */}
                        </>
                    )}
                </Row>
            )}
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: height,
                    position: "relative",
                }}
            >
                <ProChat
                    chatRef={chatRef}
                    style={{
                        maxWidth: "60rem",
                        position: "relative",
                        margin: "auto",
                    }}
                    chatItemRenderConfig={{
                        actionsRender,
                    }}
                    initialChats={sortedMessages}
                    chats={sortedMessages}
                    showTitle={false}
                    helloMessage={initialMessage}
                    loading={false}
                    locale={locale}
                    actions={{
                        flexConfig: {},
                        render: undefined,
                    }}
                    transformToChatMessage={(preChatMessage) => {
                        const lines = preChatMessage.split("\n");
                        const messages = lines
                            .map((line) => {
                                const jsonValue = line
                                    .replace(/^data: /, "")
                                    .trim();

                                if (jsonValue === "") {
                                    return "";
                                }
                                const data = JSON.parse(jsonValue);
                                if (data.chat_message_history_id) {
                                    sessionStorage.setItem(
                                        chatIdKey,
                                        data.chat_message_history_id
                                    );
                                }
                                if (data.message) {
                                    return data.message;
                                }
                            })
                            .filter(Boolean);
                        return messages.join("");
                    }}
                    userMeta={{
                        avatar: "",
                    }}
                    assistantMeta={{
                        avatar: assistantData?.data?.icon || "🤖",
                    }}
                    request={async (messages) => {
                        const latestMessage = messages[messages.length - 1];
                        const meta =
                            typeof latestMessage.content === "string"
                                ? latestMessage.content.match(
                                      /<META>(.*)<\/META>/
                                  )
                                : null;
                        if (meta) {
                            chatRef.current?.deleteMessage(latestMessage.id);
                        }
                        return sendMessageToServer(
                            apiUrl,
                            assistantId,
                            assistantUuid,
                            latestMessage,
                            isPublic
                        );
                    }}
                />
                <SuggestionComponent
                    suggestions={suggestions}
                    sendMessage={(msg) => {
                        setSuggestions([]);
                        chatRef.current?.sendMessage(msg);
                    }}
                />
            </div>
        </div>
    );
};
