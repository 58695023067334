import { Edit, useForm } from "@refinedev/antd";
import { UserGroupForm } from "./form";
import { UserGroupData } from "./types";
import { RegistrationTokenTable } from "components/registration-tokens/table";

export const UserGroupEdit = () => {
    const { formProps, saveButtonProps, queryResult, formLoading } = useForm(
        {}
    );

    return (
        <Edit saveButtonProps={saveButtonProps} isLoading={formLoading}>
            <UserGroupForm
                formProps={formProps}
                data={queryResult?.data?.data as UserGroupData | undefined}
            />
            <RegistrationTokenTable userGroupId={parseInt(queryResult?.data?.data?.id)} isEditable />
        </Edit>
    );
};
