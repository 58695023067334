import { useState, useEffect } from "react";

const TENANTS: {
    [key: string]: {
        features: {
            videos: boolean;
            documents: boolean;
            quizzes: boolean;
        };
        title: string;
        icon: string;
        defaultLanguage: string;
    };
} = {
    reforma: {
        features: {
            videos: false,
            documents: true,
            quizzes: false,
        },
        title: "Reforma",
        icon: "/static/reforma.png",
        defaultLanguage: "sl",
    },
    crackverbal: {
        features: {
            videos: true,
            documents: true,
            quizzes: true,
        },
        title: "CrackVerbal",
        icon: "/static/aceflow.webp",
        defaultLanguage: "en",
    },
    defaultTenant: {
        features: {
            videos: true,
            documents: true,
            quizzes: true,
        },
        title: "AceFlow",
        icon: "/static/aceflow.webp",
        defaultLanguage: "en",
    },
};

export function useCurrentTenant() {
    const [tenant, setTenant] = useState({
        id: "",
        defaultLanguage: "en",
        features: { videos: true, documents: true, quizzes: true },
        title: "",
        icon: "",
    });

    useEffect(() => {
        const tenantId = Object.keys(TENANTS).find(tenant => window.location.hostname.includes(tenant)) || "defaultTenant";        const tenantConfig = TENANTS[tenantId] || TENANTS.defaultTenant;

        setTenant({
            id: tenantId,
            defaultLanguage: tenantConfig.defaultLanguage,
            features: tenantConfig.features,
            title: tenantConfig.title,
            icon: tenantConfig.icon,
        });
    }, []);

    return tenant;
}
