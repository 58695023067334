import { DateField, NumberField, Show, TextField } from "@refinedev/antd";
import { useShow } from "@refinedev/core";
import { Typography } from "antd";
import { RegistrationTokenTable } from "components/registration-tokens/table";

const { Title } = Typography;

export const UserGroupShow = () => {
    const { queryResult } = useShow({});
    const { data, isLoading } = queryResult;

    const record = data?.data;

    return (
        <Show isLoading={isLoading}>
            <Title level={5}>{"ID"}</Title>
            <NumberField value={record?.id ?? ""} />
            <Title level={5}>{"Name"}</Title>
            <TextField value={record?.name} />
            <Title level={5}>{"CreatedAt"}</Title>
            <DateField value={record?.createdAt} />
            <RegistrationTokenTable userGroupId={parseInt(queryResult?.data?.data?.id)} />
        </Show>
    );
};
