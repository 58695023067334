import React, { ReactElement, useState } from "react";
import "../../components/markdown.css";
import { Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "@react-pdf-viewer/page-navigation/lib/styles/index.css";
import { highlightPlugin } from "@react-pdf-viewer/highlight";
import "@react-pdf-viewer/highlight/lib/styles/index.css";
import { searchPlugin } from "@react-pdf-viewer/search";
import "@react-pdf-viewer/search/lib/styles/index.css";
import { Worker } from "@react-pdf-viewer/core";
import {
    ToolbarProps,
    ToolbarSlot,
    TransformToolbarSlot,
    toolbarPlugin,
} from "@react-pdf-viewer/toolbar";
import { useShow } from "@refinedev/core";
import { EditButton, Show } from "@refinedev/antd";
import { Button, notification } from "antd";
import { CopyFilled, MessageOutlined } from "@ant-design/icons";
import { PanelGroup, Panel, PanelResizeHandle } from "react-resizable-panels";
import { Assistant } from "components/assistant";
import { useGetIdentity } from "@refinedev/core";
import { IIdentity } from "../../authProvider";
import { useTranslation } from "react-i18next";
/**
 *
 * For example:
 *
 * fuzzyMatch('i love', 'ilove cats') -> 'ilove'
 * fuzzyMatch('ilove', 'i love cats') -> 'i love'
 * fuzzyMatch('i.love.cats', 'i love cats') -> 'i love cats'
 * fuzzyMatch('dogs', 'i love cats') -> null
 */
export function fuzzyMatch(target: string, content: string): string | null {
    // Remove all non-alphabetic characters from target
    const sanitizedTarget = target.replace(/[^a-zA-Z]/g, "");

    // Create a RegExp pattern that ignores spaces and other non-alphabetic characters in content
    const pattern = new RegExp(
        sanitizedTarget.split("").join("[^a-zA-Z]*"),
        "i"
    );

    // Perform the match
    const match = content.match(pattern);

    return match ? match[0] : null; // use the result in your CMD + F
}

type IContentDocument = {
    id: number;
    uuid: string;
    title: string;
    content: string;
    createdAt: string;
    updatedAt: string;
};

export const ContentDocumentShow = () => {
    const { queryResult } = useShow<IContentDocument>();
    const { data, isLoading } = queryResult;

    // PDF navigator
    const pageNavigationPluginInstance = pageNavigationPlugin();
    const highlightPluginInstance = highlightPlugin();
    const searchPluginInstance = searchPlugin();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [isDocumentLoaded, setDocumentLoaded] = React.useState(false);
    const handleDocumentLoad = () => setDocumentLoaded(true);
    // const { highlight } = searchPluginInstance;

    const transform: TransformToolbarSlot = (slot: ToolbarSlot) => ({
        ...slot,
        Download: () => <></>,
        DownloadMenuItem: () => <></>,
        EnterFullScreen: () => <></>,
        EnterFullScreenMenuItem: () => <></>,
        SwitchTheme: () => <></>,
        SwitchThemeMenuItem: () => <></>,
        Open: () => <></>,
        Print: () => <></>,
    });

    const toolbarPluginInstance = toolbarPlugin();
    const { renderDefaultToolbar } = toolbarPluginInstance;

    const renderToolbar = (Toolbar: (props: ToolbarProps) => ReactElement) => (
        <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
    );

    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        sidebarTabs: () => [],
        renderToolbar,
    });

    const [isAssistantVisible, setAssistantVisible] = useState(true);

    const copyDocumentPublicLink = () => {
        const publicLink = `${window.location.origin}/document-public/show/${data?.data.uuid}`;
        navigator.clipboard.writeText(publicLink);
        notification.success({
            message: "Public link copied to clipboard",
            description: publicLink,
            duration: 2,
        });
    };
    const { t } = useTranslation();

    const { data: identity } = useGetIdentity<IIdentity>();

    if (isLoading || !identity) {
        return <div>Loading...</div>;
    }

    const record = data?.data;

    return (
        <div
            style={{
                height: "80vh",
            }}
        >
            <Show
                breadcrumb={false}
                title={record?.title}
                contentProps={{
                    style: {
                        backgroundColor: "#F5F5F5",
                        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0)",
                        margin: "-24px",
                        marginTop: "-16px",
                    },
                }}
                canEdit={identity?.is_superuser}
                headerButtons={({ editButtonProps }) => {
                    return (
                        <>
                            {identity?.is_superuser && (
                                <>
                                    <Button
                                        size="middle"
                                        onClick={copyDocumentPublicLink}
                                        icon={<CopyFilled />}
                                    >
                                        {t("actions.copyPublicLink", "Copy Public Link")}
                                    </Button>

                                    <EditButton {...editButtonProps} />
                                </>
                            )}
                            <Button
                                type="primary"
                                onClick={() =>
                                    setAssistantVisible(!isAssistantVisible)
                                }
                                icon={<MessageOutlined />}
                            >
                                {t(
                                    "assistant.toggleAssistant",
                                    "Toggle Assistant"
                                )}
                            </Button>
                        </>
                    );
                }}
            >
                <PanelGroup direction="horizontal">
                    <Panel id="P-document" minSize={40}>
                        {record.document && (
                            <div
                                style={{
                                    height: "80vh",
                                    overflow: "hidden",
                                }}
                            >
                                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                                    <Viewer
                                        fileUrl={record.document}
                                        plugins={[
                                            pageNavigationPluginInstance,
                                            defaultLayoutPluginInstance,
                                            highlightPluginInstance,
                                            searchPluginInstance,
                                        ]}
                                        onDocumentLoad={handleDocumentLoad}
                                    />
                                </Worker>
                            </div>
                        )}
                        {!record.document && <div> No document uploaded </div>}
                    </Panel>
                    {isAssistantVisible && (
                        <>
                            <PanelResizeHandle />
                            <Panel id="P-assistant" minSize={30}>
                                <Assistant
                                    assistantId={record.assistant}
                                    height="75vh"
                                />
                            </Panel>
                        </>
                    )}
                </PanelGroup>
            </Show>
        </div>
    );
};
