import { RefineThemes } from "@refinedev/antd";
import { ConfigProvider, theme } from "antd";
import { PropsWithChildren, createContext, useEffect, useState } from "react";

type ColorModeContextType = {
    mode: "light" | "dark" | undefined;
    setMode: (mode: string) => void;
};

export const ColorModeContext = createContext<ColorModeContextType>(
    {} as ColorModeContextType
);

export const ColorModeContextProvider: React.FC<PropsWithChildren> = ({
    children,
}) => {
    const colorModeFromLocalStorage = localStorage.getItem("colorMode");
    const isSystemPreferenceDark = window?.matchMedia(
        "(prefers-color-scheme: dark)"
    ).matches;

    const systemPreference = isSystemPreferenceDark ? "dark" : "light";
    const [mode, setMode] = useState(
        "light"
        // colorModeFromLocalStorage || systemPreference
    );

    useEffect(() => {
        window.localStorage.setItem("colorMode", mode);
    }, [mode]);

    const setColorMode = () => {
        if (mode === "light") {
            setMode("dark");
        } else {
            setMode("light");
        }
    };

    const fontFamily = [
        "Lexend Deca",
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
    ].join(",");

    return (
        <ColorModeContext.Provider
            value={{
                setMode: setColorMode,
                mode: mode as "light" | "dark" | undefined,
            }}
        >
            <ConfigProvider
                // you can change the theme colors here. example: ...RefineThemes.Magenta,
                theme={{
                    ...RefineThemes.Blue,
                    //   algorithm: mode === "light" ? theme.defaultAlgorithm : theme.darkAlgorithm,
                    // TODO: Change this back when fixing dark mode
                    algorithm: theme.defaultAlgorithm,
                    token: {
                        fontFamily,
                    },
                }}
            >
                {children}
            </ConfigProvider>
        </ColorModeContext.Provider>
    );
};
