import React, { ReactElement } from "react";
import "../../components/markdown.css";
import { Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "@react-pdf-viewer/page-navigation/lib/styles/index.css";
import { highlightPlugin } from "@react-pdf-viewer/highlight";
import "@react-pdf-viewer/highlight/lib/styles/index.css";
import { searchPlugin } from "@react-pdf-viewer/search";
import "@react-pdf-viewer/search/lib/styles/index.css";
import { Worker } from "@react-pdf-viewer/core";
import {
    ToolbarProps,
    ToolbarSlot,
    TransformToolbarSlot,
    toolbarPlugin,
} from "@react-pdf-viewer/toolbar";
import { useApiUrl, useCustom } from "@refinedev/core";
import { PanelGroup, Panel } from "react-resizable-panels";
import { Assistant } from "components/assistant";
import { useParsed } from "@refinedev/core";

type IContentDocument = {
    id: number;
    uuid: string;
    title: string;
    content: string;
    createdAt: string;
    updatedAt: string;
};

type UUIDParams = {
    uuid: string;
};

export const ContentDocumentShowPublic = () => {
    const { params } = useParsed<UUIDParams>();
    const uuid = params?.uuid;

    const apiUrl = useApiUrl();

    const { data, isLoading } = useCustom<IContentDocument>({
        url: `${apiUrl}/public/content-documents/${uuid}`,
        method: "get",
    });

    // PDF navigator
    const pageNavigationPluginInstance = pageNavigationPlugin();
    const highlightPluginInstance = highlightPlugin();
    const searchPluginInstance = searchPlugin();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [isDocumentLoaded, setDocumentLoaded] = React.useState(false);
    const handleDocumentLoad = () => setDocumentLoaded(true);
    // const { highlight } = searchPluginInstance;

    const transform: TransformToolbarSlot = (slot: ToolbarSlot) => ({
        ...slot,
        Download: () => <></>,
        DownloadMenuItem: () => <></>,
        EnterFullScreen: () => <></>,
        EnterFullScreenMenuItem: () => <></>,
        SwitchTheme: () => <></>,
        SwitchThemeMenuItem: () => <></>,
        Open: () => <></>,
        Print: () => <></>,
    });

    const toolbarPluginInstance = toolbarPlugin();
    const { renderDefaultToolbar } = toolbarPluginInstance;

    const renderToolbar = (Toolbar: (props: ToolbarProps) => ReactElement) => (
        <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
    );

    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        sidebarTabs: () => [],
        renderToolbar,
    });

    if (isLoading) {
        return <div>Loading...</div>;
    }

    const record = data?.data;

    return (
        <div
            style={{
                height: "100vh",
                backgroundColor: "#F5F5F5",
            }}
        >
            <PanelGroup
                direction="horizontal"
            >
                <Panel id="P-document" defaultSize={60} minSize={40}>
                    {record.document && (
                        <div
                            style={{
                                height: "100vh",
                                overflow: "hidden",
                            }}
                        >
                            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                                <Viewer
                                    fileUrl={record.document}
                                    plugins={[
                                        pageNavigationPluginInstance,
                                        defaultLayoutPluginInstance,
                                        highlightPluginInstance,
                                        searchPluginInstance,
                                    ]}
                                    onDocumentLoad={handleDocumentLoad}
                                />
                            </Worker>
                        </div>
                    )}
                    {!record.document && <div> No document uploaded </div>}
                </Panel>
                <Panel id="P-assistant" minSize={30}>
                    <Assistant
                        assistantId={0}
                        assistantUuid={record.assistant_uuid}
                        height="100vh"
                        isPublic
                    />
                </Panel>
            </PanelGroup>
        </div>
    );
};
