import React from "react";
import {
    useTranslate,
    useLogout,
    useTitle,
    CanAccess,
    ITreeMenu,
    useIsExistAuthentication,
    useRouterContext,
    useMenu,
    useLink,
    useRouterType,
    useActiveAuthProvider,
    pickNotDeprecated,
    useWarnAboutChange,
    useOne,
} from "@refinedev/core";
import { ThemedTitleV2, useThemedLayoutContext } from "@refinedev/antd";
import {
    LogoutOutlined,
    UnorderedListOutlined,
    LeftOutlined,
    RightOutlined,
    DashboardOutlined,
} from "@ant-design/icons";
import {
    Layout,
    Menu,
    Grid,
    Drawer,
    Button,
    theme,
    Progress,
} from "antd";
import type { RefineThemedLayoutV2SiderProps } from "@refinedev/antd";
import { usePermissions } from "@refinedev/core";
import { ProgressProps } from "antd/lib";
import { useTranslation } from "react-i18next";

export const ThemedSiderV2: React.FC<RefineThemedLayoutV2SiderProps> = ({
    Title: TitleFromProps,
    render,
    meta,
    fixed,
    activeItemDisabled = false,
}) => {
    const { token } = theme.useToken();
    const {
        siderCollapsed,
        setSiderCollapsed,
        mobileSiderOpen,
        setMobileSiderOpen,
    } = useThemedLayoutContext();

    const isExistAuthentication = useIsExistAuthentication();
    const routerType = useRouterType();
    const NewLink = useLink();
    const { warnWhen, setWarnWhen } = useWarnAboutChange();
    const { Link: LegacyLink } = useRouterContext();
    const Link = routerType === "legacy" ? LegacyLink : NewLink;
    const TitleFromContext = useTitle();
    const translate = useTranslate();
    const { menuItems, selectedKey, defaultOpenKeys } = useMenu({ meta });
    const breakpoint = Grid.useBreakpoint();
    const authProvider = useActiveAuthProvider();
    const { mutate: mutateLogout } = useLogout({
        v3LegacyAuthProviderCompatible: Boolean(authProvider?.isLegacy),
    });

    const isMobile =
        typeof breakpoint.lg === "undefined" ? false : !breakpoint.lg;

    const RenderToTitle = TitleFromProps ?? TitleFromContext ?? ThemedTitleV2;

    const renderTreeView = (tree: ITreeMenu[], selectedKey?: string) => {
        return tree.map((item: ITreeMenu) => {
            const {
                icon,
                label,
                route,
                key,
                name,
                children,
                parentName,
                meta,
                options,
            } = item;

            if (children.length > 0) {
                return (
                    <CanAccess
                        key={item.key}
                        resource={name.toLowerCase()}
                        action="list"
                        params={{
                            resource: item,
                        }}
                    >
                        <Menu.SubMenu
                            key={item.key}
                            icon={icon ?? <UnorderedListOutlined />}
                            title={label}
                        >
                            {renderTreeView(children, selectedKey)}
                        </Menu.SubMenu>
                    </CanAccess>
                );
            }
            const isSelected = key === selectedKey;
            const isRoute = !(
                pickNotDeprecated(meta?.parent, options?.parent, parentName) !==
                    undefined && children.length === 0
            );

            const linkStyle: React.CSSProperties =
                activeItemDisabled && isSelected
                    ? { pointerEvents: "none" }
                    : {};

            return (
                <CanAccess
                    key={item.key}
                    resource={name.toLowerCase()}
                    action="list"
                    params={{
                        resource: item,
                    }}
                >
                    <Menu.Item
                        key={item.key}
                        icon={icon ?? (isRoute && <UnorderedListOutlined />)}
                        style={linkStyle}
                    >
                        <Link to={route ?? ""} style={linkStyle}>
                            {label}
                        </Link>
                        {!siderCollapsed && isSelected && (
                            <div className="ant-menu-tree-arrow" />
                        )}
                    </Menu.Item>
                </CanAccess>
            );
        });
    };

    const handleLogout = () => {
        if (warnWhen) {
            const confirm = window.confirm(
                translate(
                    "warnWhenUnsavedChanges",
                    "Are you sure you want to leave? You have unsaved changes."
                )
            );

            if (confirm) {
                setWarnWhen(false);
                mutateLogout();
            }
        } else {
            mutateLogout();
        }
    };

    const logout = isExistAuthentication && (
        <Menu.Item
            key="logout"
            onClick={() => handleLogout()}
            icon={<LogoutOutlined />}
        >
            {translate("buttons.logout", "Logout")}
        </Menu.Item>
    );

    const dashboard = (
        <Menu.Item key="dashboard" icon={<DashboardOutlined />}>
            <Link to="/">{translate("dashboard.title", "Dashboard")}</Link>
            {!siderCollapsed && selectedKey === "/" && (
                <div className="ant-menu-tree-arrow" />
            )}
        </Menu.Item>
    );

    const items = renderTreeView(menuItems, selectedKey);
    const filteredItems = items.filter(
        (item) => item.key !== "/subchatbots" && item.key !== "/documents"
    );
    const adminItems = filteredItems.filter(
        (item) => item.props.params.resource.meta?.adminOnly === true
    );
    const userItems = filteredItems.filter(
        (item) => item.props.params.resource.meta?.adminOnly !== true
    );

    const { data: permissionsData } = usePermissions({});

    const renderSider = () => {
        if (render) {
            return render({
                dashboard,
                items,
                logout,
                collapsed: siderCollapsed,
            });
        }
        return (
            <>
                {permissionsData?.includes("admin") && adminItems}
                {userItems}
            </>
        );
    };

    const {t} = useTranslation();

    const usageMeter = () => {
        const twoColors: ProgressProps["strokeColor"] = {
            "0%": "#3498db",
            "100%": "#e94949",
        };

        const { data: userGroupData, isLoading: isUserGroupDataLoading } =
        useOne({
            resource: "user-groups",
            id: 1,
        });

        const {usage: used, usage_limit: total} = userGroupData?.data || {};

        const color = used >= total ? "red" : "black";

        if (isUserGroupDataLoading) {
            return null;
        }

        return (
            // <Card
            //     title={<div style={{ textAlign: "center" }}>AI Usage</div>}
            //     style={{
            //         borderRadius: "8px",
            //         margin: "1rem",
            //     }}
            // >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                    }}
                >
                    <Progress
                        size="small"
                        style={{ color: "#3498db" }}
                        type="dashboard"
                        strokeColor={twoColors}
                        percent={(used / total) * 100} // 4/5 expressed as a percentage
                        format={() => (
                            <div style={{ color }}>
                                {used}/{total}
                            </div>
                        )}
                    />
                    <h3 className="mt-2">{t("stats.usage", "Usage")}</h3>
                </div>
            // </Card>
        );
    };

    const renderMenu = () => {
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "calc(100% - 72px)",
                }}
            >
                <Menu
                    selectedKeys={selectedKey ? [selectedKey] : []}
                    defaultOpenKeys={defaultOpenKeys}
                    mode="inline"
                    style={{
                        paddingTop: "8px",
                        border: "none",
                        overflow: "auto",
                        flex: 1,
                    }}
                    onClick={() => {
                        setMobileSiderOpen(false);
                    }}
                >
                    {renderSider()}
                </Menu>
                {usageMeter()}
            </div>
        );
    };

    const renderDrawerSider = () => {
        return (
            <>
                <Drawer
                    open={mobileSiderOpen}
                    onClose={() => setMobileSiderOpen(false)}
                    placement="left"
                    closable={false}
                    width={200}
                    styles={{
                        body: {
                            padding: 0,
                        },
                    }}
                    maskClosable={true}
                >
                    <Layout>
                        <Layout.Sider
                            style={{
                                height: "100vh",
                                backgroundColor: token.colorBgContainer,
                                borderRight: `1px solid ${token.colorBgElevated}`,
                            }}
                        >
                            <div
                                style={{
                                    width: "200px",
                                    padding: "0 16px",
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    height: "64px",
                                    backgroundColor: token.colorBgElevated,
                                }}
                            >
                                <RenderToTitle collapsed={false} />
                            </div>
                            {renderMenu()}
                        </Layout.Sider>
                    </Layout>
                </Drawer>
            </>
        );
    };

    if (isMobile) {
        return renderDrawerSider();
    }

    const siderStyles: React.CSSProperties = {
        backgroundColor: token.colorBgContainer,
        borderRight: `1px solid ${token.colorBgElevated}`,
    };

    if (fixed) {
        siderStyles.position = "fixed";
        siderStyles.top = 0;
        siderStyles.height = "100vh";
        siderStyles.zIndex = 999;
    }

    return (
        <>
            {fixed && (
                <div
                    style={{
                        width: siderCollapsed ? "80px" : "200px",
                        transition: "all 0.2s",
                    }}
                />
            )}
            <Layout.Sider
                style={siderStyles}
                collapsible
                collapsed={siderCollapsed}
                onCollapse={(collapsed, type) => {
                    if (type === "clickTrigger") {
                        setSiderCollapsed(collapsed);
                    }
                }}
                collapsedWidth={80}
                breakpoint="lg"
                trigger={
                    <Button
                        type="text"
                        style={{
                            borderRadius: 0,
                            height: "100%",
                            width: "100%",
                            backgroundColor: token.colorBgElevated,
                        }}
                    >
                        {siderCollapsed ? (
                            <RightOutlined
                                style={{
                                    color: token.colorPrimary,
                                }}
                            />
                        ) : (
                            <LeftOutlined
                                style={{
                                    color: token.colorPrimary,
                                }}
                            />
                        )}
                    </Button>
                }
            >
                <div
                    style={{
                        width: siderCollapsed ? "80px" : "200px",
                        padding: siderCollapsed ? "0" : "0 16px",
                        display: "flex",
                        justifyContent: siderCollapsed
                            ? "center"
                            : "flex-start",
                        alignItems: "center",
                        height: "64px",
                        backgroundColor: token.colorBgElevated,
                        fontSize: "14px",
                    }}
                >
                    <RenderToTitle collapsed={siderCollapsed} />
                </div>
                {renderMenu()}
            </Layout.Sider>
        </>
    );
};
