import "../../components/markdown.css";
import ReactPlayer from "react-player";

import { useApiUrl, useCustom, useParsed } from "@refinedev/core";
import { Card } from "antd";
import { PanelGroup, Panel } from "react-resizable-panels";
import { Assistant } from "components/assistant";

type IContentVideo = {
    id: number;
    uuid: string;
    title: string;
    content: string;
    createdAt: string;
    updatedAt: string;
};

type UUIDParams = {
    uuid: string;
};

export const ContentVideoShowPublic = () => {
    const { params } = useParsed<UUIDParams>();
    const uuid = params?.uuid;

    const apiUrl = useApiUrl();

    const { data, isLoading } = useCustom<IContentVideo>({
        url: `${apiUrl}/public/content-videos/${uuid}`,
        method: "get",
    });

    if (isLoading) {
        return <div>Loading...</div>;
    }

    const record = data?.data;

    return (
        <div
            style={{
                height: "100vh",
                backgroundColor: "#F5F5F5",
            }}
        >
            <PanelGroup
                direction="horizontal"
            >
                <Panel id="P-video" defaultSize={6} minSize={40}>
                    <Card
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                        }}
                    >
                        {record.video && (
                            <ReactPlayer
                                url={record.video}
                                controls
                                width="100%"
                            />
                        )}
                        {!record.video && <div> No video uploaded </div>}
                    </Card>
                </Panel>
                <Panel id="P-assistant" minSize={30}>
                    <Assistant
                        assistantId={0}
                        assistantUuid={record.assistant_uuid}
                        height="100vh"
                        isPublic
                    />
                </Panel>
            </PanelGroup>
        </div>
    );
};
