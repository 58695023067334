import { useParsed } from "@refinedev/core";
import { Assistant } from "components/assistant";

type UUIDParams = {
    uuid: string;
};

export const AssistantShowPublic = () => {
    const { params } = useParsed<UUIDParams>();
    const uuid = params?.uuid;

    if (!uuid) {
        return <div>Loading...</div>;
    }

    return (
        <div
            style={{
                backgroundColor: "#f5f5f5",
                height: "100vh",
            }}
        >
            <Assistant
                assistantId={0}
                assistantUuid={uuid}
                height="100%"
                isPublic
            />
        </div>
    );
};
