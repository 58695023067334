import React from "react";
import { useRouterContext, useRouterType, useLink } from "@refinedev/core";
import { Typography, theme, Space } from "antd";
import type { RefineLayoutThemedTitleProps } from "@refinedev/antd";
import { useCurrentTenant } from "tenants";

const defaultText = "AceFlow";
const defaultIcon = "/static/aceflow.webp";

export const ThemedTitleV2: React.FC<RefineLayoutThemedTitleProps> = ({
    collapsed,
    text = defaultText,
    icon = defaultIcon,
    wrapperStyles,
}) => {
    const { token } = theme.useToken();
    const routerType = useRouterType();
    const Link = useLink();
    const { Link: LegacyLink } = useRouterContext();

    const {title, icon: tenantIcon} = useCurrentTenant();

    const ActiveLink = routerType === "legacy" ? LegacyLink : Link;

    return (
        <ActiveLink
            to="/"
            style={{
                display: "inline-block",
                textDecoration: "none",
            }}
        >
            <Space
                style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "inherit",
                    ...wrapperStyles,
                }}
            >
                <div style={{overflow: "hidden"}}>
                    <img
                        src={(tenantIcon || icon) as string}
                        className="h-6"
                        alt="Logo"
                        style={{
                            transform: "translateY(-1000px)",
                            filter: `drop-shadow(0px 1000px 0 ${token.colorPrimary})`,
                        }}
                    />
                </div>
                {!collapsed && (
                    <Typography.Title
                        style={{
                            fontSize: "inherit",
                            marginBottom: 0,
                            fontWeight: 600,
                        }}
                    >
                        {title || text}
                    </Typography.Title>
                )}
            </Space>
        </ActiveLink>
    );
};
