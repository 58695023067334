import { Form, Input, Select, Upload } from "antd";
import { AssistantType } from "./types";
import { getValueFromEvent } from "@refinedev/antd";
import { FormProps } from "antd/lib";
import { MarkdownEditor } from "components/markdown-editor";
import { useTranslation } from "react-i18next";

export const AssistantForm = ({
    data,
    formProps,
}: {
    data?: AssistantType;
    formProps: FormProps;
}) => {

    const { t } = useTranslation();

    const LANGUAGE_CHOICES = [
        { value: "en", label: t("assistants.fields.languages.english", "English") },
        { value: "sl", label: t("assistants.fields.languages.slovenian", "Slovenian") },
    ];

    const LANGUAGE_MODEL_CHOICES = [
        { value: "GPT-3.5 Turbo", label: t("assistants.fields.models.gpt3.5", "GPT-3.5 Turbo") },
        { value: "GPT-4 Turbo", label: t("assistants.fields.models.gpt4", "GPT-4 Turbo") },
        { value: "GPT-4o", label: t("assistants.fields.models.gpt4o", "GPT-4o") },
        { value: "Claude 3 Opus", label: t("assistants.fields.models.claude3opus", "Claude 3 Opus") },
        { value: "Claude 3 Sonnet", label: t("assistants.fields.models.claude3sonnet", "Claude 3 Sonnet") },
        { value: "Claude 3 Haiku", label: t("assistants.fields.models.claude3haiku", "Claude 3 Haiku") },
        { value: "Assistants API", label: t("assistants.fields.models.assistantsApi", "ZDR 2024 Hardcoded Assistants API") },
    ];

    if (!formProps) {
        return null;
    }

    return (
        <Form
            {...formProps}
            layout="vertical"
            initialValues={data}
            onFinish={async (values) => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const { icons, ...otherValues } = values as any;
                const icon =
                    (icons && icons[0] && icons[0].originFileObj) || null;
                if (icon) {
                    otherValues.icon = icon;
                }
                return formProps.onFinish?.({
                    ...otherValues,
                });
            }}
        >
             <Form.Item
                label={t("assistants.fields.name", "Name")}
                name={["name"]}
                rules={[{ required: true }]}
            >
                <Input />
            </Form.Item>
            <Form.Item label={t("assistants.fields.language", "Language")} name={["language"]} required>
                <Select options={LANGUAGE_CHOICES} />
            </Form.Item>
            <Form.Item
                label={t("assistants.fields.initialMessage", "Initial Message")}
                name={["initial_message"]}
                rules={[{ required: true }]}
            >
                <MarkdownEditor preview="edit" />
            </Form.Item>
            <Form.Item label={t("assistants.fields.icon", "Icon")}>
                <Form.Item
                    name="icons"
                    valuePropName="file"
                    getValueFromEvent={getValueFromEvent}
                    noStyle
                >
                    <Upload.Dragger
                        listType="picture"
                        maxCount={1}
                        defaultFileList={[
                            {
                                uid: "-1",
                                name: "image.png",
                                status: "done",
                                url: data?.icon || "",
                            },
                        ]}
                        beforeUpload={() => false}
                    >
                        <p className="ant-upload-text">
                            {t("assistants.fields.dragDropText", "Drag & drop a file in this area")}
                        </p>
                    </Upload.Dragger>
                </Form.Item>
            </Form.Item>
            <Form.Item
                label={t("assistants.fields.customPromptPrepend", "Custom Prompt Prepend")}
                name={["custom_prompt_prepend"]}
                rules={[{ required: false }]}
            >
                <MarkdownEditor preview="edit" />
            </Form.Item>
            <Form.Item label={t("assistants.fields.languageModel", "Language Model")} name={["language_model"]} required>
                <Select options={LANGUAGE_MODEL_CHOICES} />
            </Form.Item>
        </Form>
    );
};
