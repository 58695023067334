import { Form, FormProps, Input, InputNumber } from "antd";
import { UserGroupData } from "./types";
import { useTranslation } from 'react-i18next'; // Import useTranslation hook

export const UserGroupForm = ({
    formProps,
    data,
}: {
    formProps: FormProps;
    data: UserGroupData | undefined;
}) => {
    const { t } = useTranslation();

    return (
        <Form {...formProps} layout="vertical" initialValues={data}>
            <Form.Item
                label={t("userGroupForm.name", "Name")}
                name={["name"]}
                rules={[{ required: true }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label={t("userGroupForm.usageLimit", "Usage Limit")} // Use translation key
                name={["usage_limit"]}
                rules={[{ required: true, min: 0, max: 10000, type: "number" }]}
            >
                <InputNumber/>
            </Form.Item>
        </Form>
    );
};
