import { useState } from "react";
import "../../components/markdown.css";
import ReactPlayer from "react-player";

import { useShow } from "@refinedev/core";
import { EditButton, Show } from "@refinedev/antd";
import { Button, Card, notification } from "antd";
import { CopyFilled, MessageOutlined } from "@ant-design/icons";
import { PanelGroup, Panel, PanelResizeHandle } from "react-resizable-panels";
import { Assistant } from "components/assistant";
import { useGetIdentity } from "@refinedev/core";
import { IIdentity } from "../../authProvider";
import { useTranslation } from "react-i18next";
type IContentVideo = {
    id: number;
    uuid: string;
    title: string;
    content: string;
    createdAt: string;
    updatedAt: string;
};

export const ContentVideoShow = () => {
    const { queryResult } = useShow<IContentVideo>();
    const { data, isLoading } = queryResult;

    const [isAssistantVisible, setAssistantVisible] = useState(true);

    const copyVideoPublicLink = () => {
        const publicLink = `${window.location.origin}/video-public/show/${data?.data.uuid}`;
        navigator.clipboard.writeText(publicLink);
        notification.success({
            message: "Public link copied to clipboard",
            description: publicLink,
            duration: 2,
        });
    };
    const { t } = useTranslation();

    const { data: identity } = useGetIdentity<IIdentity>();

    if (isLoading || !identity) {
        return <div>Loading...</div>;
    }

    const record = data?.data;

    return (
        <div
            style={{
                height: "80vh",
            }}
        >
            <Show
                breadcrumb={false}
                title={record?.title}
                contentProps={{
                    style: {
                        backgroundColor: "#F5F5F5",
                        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0)",
                        margin: "-24px",
                        marginTop: "-16px",
                    },
                }}
                canEdit={identity?.is_superuser}
                headerButtons={({ editButtonProps }) => {
                    return (
                        <>
                            {identity?.is_superuser && (
                                <>
                                    <Button
                                        size="middle"
                                        onClick={copyVideoPublicLink}
                                        icon={<CopyFilled />}
                                    >
                                        {t("actions.copyPublicLink", "Copy Public Link")}
                                    </Button>
                                    <EditButton {...editButtonProps} />
                                </>
                            )}
                            <Button
                                type="primary"
                                onClick={() =>
                                    setAssistantVisible(!isAssistantVisible)
                                }
                                icon={<MessageOutlined />}
                            >
                                {t(
                                    "assistant.toggleAssistant",
                                    "Toggle Assistant"
                                )}
                            </Button>
                        </>
                    );
                }}
            >
                <PanelGroup direction="horizontal">
                    <Panel id="P-video" minSize={40}>
                        <Card
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "80vh",
                            }}
                        >
                            {record.video && (
                                <ReactPlayer
                                    url={record.video}
                                    controls
                                    width="100%"
                                />
                            )}
                            {!record.video && <div> No video uploaded </div>}
                        </Card>
                    </Panel>
                    {isAssistantVisible && (
                        <>
                            <PanelResizeHandle />
                            <Panel id="P-assistant" minSize={30}>
                                <Assistant
                                    assistantId={record.assistant}
                                    height="75vh"
                                />
                            </Panel>
                        </>
                    )}
                </PanelGroup>
            </Show>
        </div>
    );
};
