import { List } from "@refinedev/antd";
import { ThumbnailList } from "components/content-document/thumbnailList";
import { useTable } from "@refinedev/antd";
import { useGetIdentity } from "@refinedev/core";
import { IIdentity } from "../../authProvider";
import { useTranslation } from "react-i18next";

export const ContentDocumentList = () => {
    const { tableQueryResult } = useTable({
        syncWithLocation: true,
    });

    const { t } = useTranslation();

    const { data: identity } = useGetIdentity<IIdentity>();

    if (tableQueryResult.isLoading || !identity) {
        return <div>Loading...</div>;
    }

    return (
        <List
            canCreate={identity?.is_superuser}
            title={t("resources.documents", "Documents")}
        >
            <ThumbnailList contentDocuments={tableQueryResult?.data?.data} />
        </List>
    );
};
