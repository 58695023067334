import {
    Authenticated,
    Refine,
    DataProvider,
    usePermissions,
} from "@refinedev/core";
import { DevtoolsPanel, DevtoolsProvider } from "@refinedev/devtools";
import { RefineKbar, RefineKbarProvider } from "@refinedev/kbar";
import { ErrorComponent, useNotificationProvider } from "@refinedev/antd";
import { App as AntdApp } from "antd";
import {
    ApiOutlined,
    BookOutlined,
    DashboardOutlined,
    HddOutlined,
    TeamOutlined,
    UserOutlined,
    VideoCameraOutlined,
} from "@ant-design/icons";
import "@refinedev/antd/dist/reset.css";
import routerBindings, {
    CatchAllNavigate,
    DocumentTitleHandler,
    UnsavedChangesNotifier,
} from "@refinedev/react-router-v6";
import {
    BrowserRouter,
    Navigate,
    Outlet,
    Route,
    Routes,
} from "react-router-dom";

import { ThemedLayoutV2 } from "components/layout";
import { Header } from "components/layout/header";
import { ThemedSiderV2 } from "components/layout/sider";
import { ThemedTitleV2 } from "components/layout/title";
import { dataProvider } from "api";

import { authProvider, useTokenRefresh } from "./authProvider";
import { ColorModeContextProvider } from "contexts/color-mode";
import { ForgotPassword } from "pages/forgotPassword";
import { Login } from "pages/login";
import { Register } from "pages/register";
import "./tailwind.css";
import "./lang/i18n.ts";
import { useTranslation } from "react-i18next";

import { DashboardPage } from "./pages/dashboard";
import {
    UserGroupCreate,
    UserGroupEdit,
    UserGroupList,
    UserGroupShow,
} from "./pages/user-groups";

import { UserCreate, UserEdit, UserList, UserShow } from "./pages/users";
import { QuizCreate, QuizEdit, QuizList, QuizShow } from "pages/quizzes";
import {
    AssistantCreate,
    AssistantEdit,
    AssistantList,
    AssistantShow,
} from "pages/assistants";
import {
    ContentDocumentShow,
    ContentDocumentList,
    ContentDocumentEdit,
    ContentDocumentCreate,
} from "pages/content-documents";
import {
    ContentVideoCreate,
    ContentVideoEdit,
    ContentVideoList,
    ContentVideoShow,
} from "pages/content-videos";
import { ContentDocumentShowPublic } from "pages/content-documents/show-public";
import { ContentVideoShowPublic } from "pages/content-videos/show-public";
import { AssistantShowPublic } from "pages/assistants/show-public";
import { QuizShowPublic } from "pages/quizzes/show-public";
import {
    UpdatePasswordPage,
    VerifyEmailPage,
} from "components/pages/auth/components";
import { useCurrentTenant } from "./tenants";
import { useEffect } from "react";

const PermissionsBasedRedirect = () => {
    const { data: permissionsData } = usePermissions({});

    if (permissionsData?.includes("admin")) {
        return <DashboardPage />;
    } else {
        return <Navigate to="/content-documents/list/" replace />;
    }
};
import moment from 'moment/min/moment-with-locales';

import {i18nProvider} from "./lang/i18n";
import { ChatMessageHistoryShow } from "pages/dashboard/chat-message-history";

function App() {
    useTokenRefresh();
    const { t, i18n } = useTranslation();

    const { defaultLanguage, features } = useCurrentTenant();

    useEffect(() => {
        i18n.changeLanguage(defaultLanguage);
        // change the language of the moment library
        console.log(moment.locales())
        moment.locale("sl");
    }, [defaultLanguage]); // This will only re-run if defaultLanguage changes

    const apiUrl = window.location.origin + "/api";

    return (
        <BrowserRouter>
            <ColorModeContextProvider>
                <RefineKbarProvider>
                    <AntdApp>
                        <DevtoolsProvider>
                            <Refine
                                i18nProvider={i18nProvider}
                                dataProvider={
                                    dataProvider(apiUrl) as DataProvider
                                }
                                notificationProvider={useNotificationProvider}
                                routerProvider={routerBindings}
                                authProvider={authProvider}
                                resources={[
                                    {
                                        name: "dashboard",
                                        list: "/dashboard",
                                        meta: {
                                            adminOnly: true,
                                            icon: <DashboardOutlined />,
                                            label: t(
                                                "resources.dashboard",
                                                "Dashboard"
                                            ),
                                        },
                                    },
                                    {
                                        name: "user-groups",
                                        list: "/user-groups",
                                        create: "/user-groups/create",
                                        edit: "/user-groups/edit/:id",
                                        show: "/user-groups/show/:id",
                                        meta: {
                                            canDelete: true,
                                            adminOnly: true,
                                            icon: <TeamOutlined />,
                                            label: t(
                                                "resources.groups",
                                                "Groups"
                                            ),
                                        },
                                    },
                                    {
                                        name: "users",
                                        list: "/users",
                                        create: "/users/create",
                                        edit: "/users/edit/:id",
                                        show: "/users/show/:id",
                                        meta: {
                                            label: t(
                                                "resources.users",
                                                "Users"
                                            ),
                                            canDelete: true,
                                            adminOnly: true,
                                            icon: <UserOutlined />,
                                        },
                                    },
                                    {
                                        name: "content-documents",
                                        list: "/content-documents/list",
                                        show: "/content-documents/show/:id",
                                        create: "/content-documents/create",
                                        edit: "/content-documents/edit/:id",
                                        meta: {
                                            label: t(
                                                "resources.documents",
                                                "Documents"
                                            ),
                                            canDelete: true,
                                            icon: <BookOutlined />,
                                            hide: !features.documents,
                                        },
                                    },
                                    {
                                        name: "content-videos",
                                        list: "/content-videos/list",
                                        show: "/content-videos/show/:id",
                                        create: "/content-videos/create",
                                        edit: "/content-videos/edit/:id",
                                        meta: {
                                            label: t(
                                                "resources.vides",
                                                "Videos"
                                            ),
                                            canDelete: true,
                                            icon: <VideoCameraOutlined />,
                                            hide: !features.videos,
                                        },
                                    },
                                    {
                                        name: "quizzes",
                                        list: "/quizzes",
                                        create: "/quizzes/create",
                                        edit: "/quizzes/edit/:id",
                                        show: "/quizzes/show/:id",
                                        meta: {
                                            label: t(
                                                "resources.quizzes",
                                                "Quizzes"
                                            ),
                                            canDelete: true,
                                            icon: <HddOutlined />,
                                            hide: !features.quizzes,
                                        },
                                    },
                                    {
                                        name: "assistants",
                                        list: "/assistants",
                                        create: "/assistants/create",
                                        edit: "/assistants/edit/:id",
                                        show: "/assistants/show/:id",
                                        meta: {
                                            label: t(
                                                "resources.assistants",
                                                "Assistants"
                                            ),
                                            canDelete: true,
                                            icon: <ApiOutlined />,
                                            adminOnly: true,
                                        },
                                    },
                                    {
                                        name: "multiple-choice-questions",
                                        list: "/multiple-choice-questions",
                                        create: "/multiple-choice-questions/create",
                                        edit: "/multiple-choice-questions/edit/:id",
                                        show: "/multiple-choice-questions/show/:id",
                                        meta: {
                                            hide: true,
                                            canDelete: true,
                                        },
                                    },
                                    {
                                        name: "multiple-choice-answers",
                                        list: "/multiple-choice-answers",
                                        create: "/multiple-choice-answers/create",
                                        edit: "/multiple-choice-answers/edit/:id",
                                        show: "/multiple-choice-answers/show/:id",
                                        meta: {
                                            hide: true,
                                            canDelete: true,
                                        },
                                    },
                                ]}
                                options={{
                                    syncWithLocation: true,
                                    warnWhenUnsavedChanges: true,
                                    useNewQueryKeys: true,
                                    projectId: "gP4tlt-ePnob0-W0sNBr",
                                }}
                            >
                                <Routes>
                                    <Route
                                        element={
                                            <Authenticated
                                                key="authenticated-inner"
                                                fallback={
                                                    <CatchAllNavigate to="/login" />
                                                }
                                            >
                                                <ThemedLayoutV2
                                                    Header={Header}
                                                    Sider={ThemedSiderV2}
                                                    Title={ThemedTitleV2}
                                                >
                                                    <Outlet />
                                                </ThemedLayoutV2>
                                            </Authenticated>
                                        }
                                    >
                                        <Route
                                            index
                                            element={
                                                <PermissionsBasedRedirect />
                                            }
                                        />
                                        <Route path="/dashboard">
                                            <Route
                                                index
                                                element={<DashboardPage />}
                                            />
                                            <Route
                                                path="chat-message-history/:id"
                                                element={<ChatMessageHistoryShow />}
                                            />
                                        </Route>
                                        <Route path="/assistants">
                                            <Route
                                                index
                                                element={<AssistantList />}
                                            />
                                            <Route
                                                path="create"
                                                element={<AssistantCreate />}
                                            />
                                            <Route
                                                path="edit/:id"
                                                element={<AssistantEdit />}
                                            />
                                            <Route
                                                path="show/:id"
                                                element={<AssistantShow />}
                                            />
                                        </Route>
                                        <Route path="/users">
                                            <Route
                                                index
                                                element={<UserList />}
                                            />
                                            <Route
                                                path="create"
                                                element={<UserCreate />}
                                            />
                                            <Route
                                                path="edit/:id"
                                                element={<UserEdit />}
                                            />
                                            <Route
                                                path="show/:id"
                                                element={<UserShow />}
                                            />
                                        </Route>
                                        <Route
                                            path="/update-password"
                                            element={<UpdatePasswordPage />}
                                        />
                                        <Route path="/user-groups">
                                            <Route
                                                index
                                                element={<UserGroupList />}
                                            />
                                            <Route
                                                path="create"
                                                element={<UserGroupCreate />}
                                            />
                                            <Route
                                                path="edit/:id"
                                                element={<UserGroupEdit />}
                                            />
                                            <Route
                                                path="show/:id"
                                                element={<UserGroupShow />}
                                            />
                                        </Route>
                                        <Route path="/content-documents">
                                            <Route
                                                path="list"
                                                element={
                                                    <ContentDocumentList />
                                                }
                                            />
                                            <Route
                                                path="show/:id"
                                                element={
                                                    <ContentDocumentShow />
                                                }
                                            />
                                            <Route
                                                path="create"
                                                element={
                                                    <ContentDocumentCreate />
                                                }
                                            />
                                            <Route
                                                path="edit/:id"
                                                element={
                                                    <ContentDocumentEdit />
                                                }
                                            />
                                        </Route>
                                        <Route path="/content-videos">
                                            <Route
                                                path="list"
                                                element={<ContentVideoList />}
                                            />
                                            <Route
                                                path="show/:id"
                                                element={<ContentVideoShow />}
                                            />
                                            <Route
                                                path="create"
                                                element={<ContentVideoCreate />}
                                            />
                                            <Route
                                                path="edit/:id"
                                                element={<ContentVideoEdit />}
                                            />
                                        </Route>
                                        <Route path="/quizzes">
                                            <Route
                                                index
                                                element={<QuizList />}
                                            />
                                            <Route
                                                path="create"
                                                element={<QuizCreate />}
                                            />
                                            <Route
                                                path="edit/:id"
                                                element={<QuizEdit />}
                                            />
                                            <Route
                                                path="show/:id"
                                                element={<QuizShow />}
                                            />
                                        </Route>
                                        <Route
                                            path="*"
                                            element={<ErrorComponent />}
                                        />
                                    </Route>
                                    {/* <Route
                                        element={
                                            <Authenticated
                                                key="authenticated-outer"
                                                fallback={<Outlet />}
                                            >
                                                <NavigateToResource />
                                            </Authenticated>
                                        }
                                    >
                                    </Route> */}
                                    <Route path="/login" element={<Login />} />
                                    <Route
                                        path="/register"
                                        element={<Register />}
                                    />
                                    <Route
                                        path="/account/forgot-password"
                                        element={<ForgotPassword />}
                                    />
                                    <Route
                                        path="/account/verify-email/:token"
                                        element={<VerifyEmailPage />}
                                    />
                                    <Route
                                        path="/document-public/show/:uuid"
                                        element={<ContentDocumentShowPublic />}
                                    />
                                    <Route
                                        path="/video-public/show/:uuid"
                                        element={<ContentVideoShowPublic />}
                                    />
                                    <Route
                                        path="/assistant-public/show/:uuid"
                                        element={<AssistantShowPublic />}
                                    />
                                    <Route
                                        path="/quiz-public/show/:uuid"
                                        element={<QuizShowPublic />}
                                    />
                                </Routes>
                                <RefineKbar />
                                <UnsavedChangesNotifier />
                                <DocumentTitleHandler />
                            </Refine>
                            <DevtoolsPanel />
                        </DevtoolsProvider>
                    </AntdApp>
                </RefineKbarProvider>
            </ColorModeContextProvider>
        </BrowserRouter>
    );
}

export default App;
